'use client';

import {FC, ReactNode} from 'react';

import {LiveblocksProvider, RoomProvider} from '@liveblocks/react';

import useEntityServiceClient from '@src/hooks/useEntityServiceClient';
import {useSubdomain} from '@src/hooks/useSubdomain';
import useUser from '@src/hooks/useUser';

import {
  Presence,
  RoomEvent,
  Storage,
  ThreadMeta,
  UserMeta,
} from '@tetra-next/liveblocks-types';
import withSuspense from '../withSuspense';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Liveblocks {
    Presence: Presence;
    Storage: Storage;
    UserMeta: UserMeta;
    RoomEvent: RoomEvent;
    ThreadMetadata: ThreadMeta;
  }
}

interface LiveblocksProviderProps {
  children: ReactNode;
}

const SuspendedOrganizationRoomProvider = withSuspense(RoomProvider);

const LiveblocksRealtimeProvider: FC<LiveblocksProviderProps> = ({children}) => {
  const {user} = useUser();
  const subdomain = useSubdomain();
  const organization = user?.org_id;
  const entityServiceClient = useEntityServiceClient();

  return (
    <LiveblocksProvider
      authEndpoint={async (room) => {
        const url = new URL('/liveblocks/auth', process.env.CMS_BASE_URL);

        if (subdomain) {
          url.host = url.host.replace(new RegExp('^cms.'), `cms.${subdomain}.`);
        }

        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({room}),
        });

        return await response.json();
      }}
      resolveMentionSuggestions={async ({text}) => {
        const {data} = await entityServiceClient.findMany('organization-members', {
          filters: {
            name: {
              $containsi: text,
            },
            isTetraGuest: {
              $eq: false,
            },
          },
          fields: ['uid'],
        });

        const uids = data
          .map((member) => {
            return member.attributes.uid;
          })
          .filter(Boolean);

        return uids;
      }}
      resolveUsers={async ({userIds}) => {
        const {data} = await entityServiceClient.findMany('organization-members', {
          filters: {
            uid: {
              $in: userIds,
            },
          },
          fields: ['uid', 'name', 'avatar'],
        });

        const map = new Map<string, {name: string; avatar: string}>();
        for (const member of data) {
          if (!map.has(member.attributes.uid)) {
            map.set(member.attributes.uid, {
              name: member.attributes.name,
              avatar: member.attributes.avatar,
            });
          }
        }

        return userIds.map((userId) => {
          return map.get(userId);
        });
      }}
    >
      <SuspendedOrganizationRoomProvider
        id={`${subdomain}:${organization}`}
        initialPresence={{}}
      >
        {children}
      </SuspendedOrganizationRoomProvider>
    </LiveblocksProvider>
  );
};

export default LiveblocksRealtimeProvider;
